






















import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import UiFlexible from '@/components/ui/Flexible.vue'

export default defineComponent({
  name: 'PartnerLanding',
  components: { UiFlexible },
  setup(_props, ctx) {
    const redirect = ref()
    const { $storage } = ctx.root.$options
    const { personal } = useGetters('steps', ['personal'])

    onMounted(() => {
      redirect.value = useGetters(['content'])

      // Check step3 or step3.accessCode instead?
      if (!personal.value($storage!) || !personal.value($storage!).accessCode) {
        ctx.root.$router.push(redirect.value.content.redirect)
      }
    })

    return {
      ...useGetters(['content']),
    }
  },
})
